<template>
  <div class="uk-light uk-margin uk-margin-left uk-margin-right">
    <a class="uk-dark uk-button uk-button-default"> Faire un don </a>
  </div>
</template>

<script>
export default {
  name: "SupportProject",
  data() {
    return {};
  },
};
</script>
